<template>
  <v-container class="pa-6">
    <v-row class="align-self-start">
      <v-col cols="2" class="d-none d-md-flex"></v-col>
      <v-col>
        <v-container fluid style="width: 100vw">
          <v-row dense>
            <v-col class="d-flex flex-row">
              <v-avatar color="teal lighten-1" size="36">
                <v-icon color="white" v-text="'mdi-account-box'"></v-icon>
              </v-avatar>
              <div class="ml-2 text-h6 align-self-center" v-text="title"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels v-model="panels.search">
                <v-expansion-panel>
                  <v-expansion-panel-header class="card-title-style-1">
                    <div class="title card-title-style-1">篩選條件</div>
                    <template v-slot:actions>
                      <v-icon color="white"> $expand </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-divider></v-divider>
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="px-0">
                          <v-text-field
                            v-model="formVars.seo.value"
                            label="關鍵字搜尋"
                            :rules="formVars.seo.rules"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-title class="card-title-style-1">
                  <div>單據列表</div>
                </v-card-title>
                <v-card-text class="px-0">
                  <v-expansion-panels multiple flat>
                    <v-expansion-panel
                      class="border-none"
                      v-for="(item, tidx) in treeVars.items"
                      :key="`exp-${tidx}`"
                    >
                      <v-expansion-panel-header expand-icon="mdi-menu-down">
                        <v-row dense>
                          <v-col cols="auto"
                            ><v-chip color="green" outlined>{{
                              item.name
                            }}</v-chip>
                          </v-col>
                          <v-col
                            class="d-flex align-self-center justify-center"
                          >
                            <v-divider
                              class="d-none d-md-flex green"
                              style="max-width: 90%"
                            ></v-divider>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-container class="pa-0">
                          <v-row dense>
                            <template v-if="!item.children">
                              <v-col cols="12"
                                ><div class="subtitle-2">無資料</div></v-col
                              >
                            </template>
                            <template v-else>
                              <v-col cols="12">
                                <v-expansion-panels multiple flat>
                                  <v-expansion-panel
                                    v-for="(citem, cidx) in item.children"
                                    :key="`exp-${tidx}-child-${cidx}`"
                                  >
                                    <v-expansion-panel-header
                                      expand-icon="mdi-menu-down"
                                      class="pa-0"
                                    >
                                      <!-- <v-row dense>
                                        <v-col cols="auto">{{
                                          citem.name
                                        }}</v-col>
                                      </v-row> -->
                                      <v-card>
                                        <v-card-title
                                          class="py-2 subtitle-2 card-title-style-1"
                                          >{{ citem.name }}</v-card-title
                                        >
                                      </v-card>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <!-- <v-divider></v-divider> -->
                                      <v-card>
                                        <!-- <v-card-subtitle
                                          class="mb-2 py-1 subtitle-2 card-title-style-1"
                                          >{{ citem.name }}</v-card-subtitle
                                        > -->
                                        <v-card-text class="px-2">
                                          <v-row dense>
                                            <template
                                              v-for="(v, k) in instVars.headers"
                                            >
                                              <v-col
                                                cols="12"
                                                :key="`hd-${tidx}-${cidx}-${k}`"
                                              >
                                                <div
                                                  class="d-sm-flex flex-sm-row"
                                                >
                                                  <div>
                                                    <v-chip label>{{
                                                      `${v.label}`
                                                    }}</v-chip>
                                                  </div>
                                                  <div
                                                    class="ml-2 align-self-center"
                                                  >
                                                    {{
                                                      `${
                                                        instVars.items[
                                                          citem.rid
                                                        ][v.id]
                                                      }`
                                                    }}
                                                  </div>
                                                </div>
                                              </v-col>
                                            </template>
                                          </v-row>
                                        </v-card-text>
                                      </v-card>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                              </v-col>
                            </template>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <!-- <v-row>
                  <v-col cols="5">
                    <v-treeview
                      :active.sync="active"
                      :items="items"
                      :load-children="fetchUsers"
                      :open.sync="open"
                      activatable
                      color="warning"
                      open-on-click
                      transition
                    >
                      <template v-slot:prepend="{ item }">
                        <v-icon v-if="!item.children">
                          mdi-account
                        </v-icon>
                      </template>
                    </v-treeview>
                  </v-col>

                  <v-divider vertical></v-divider>
                </v-row> -->
                  <!-- <v-treeview
                  :active.sync="treeVars.active"
                  dense
                  hoverable
                  activatable
                  :items="treeVars.items"
                >
                  <template slot="append" slot-scope="{ item }">
                    <template v-if="item.children == null">
                      <v-card>
                        <v-card-title
                          ><div>{{ JSON.stringify(item) }}</div></v-card-title
                        >
                      </v-card>
                    </template>
                  </template>
                </v-treeview> -->

                  <!-- <v-expansion-panels inset>
                  <v-expansion-panel
                    v-for="(item, index) in instVars.items"
                    :key="index"
                  >
                    <v-expansion-panel-header expand-icon="mdi-menu-down">
                      <v-row dense>
                        <v-col cols="auto">【群組單號】{{ item.ref_seq }}</v-col>
                        <v-col cols="auto">【需求單號】{{ item.is_seq }}</v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-divider></v-divider>
                      <v-container>
                        <v-row> </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="2" class="d-none d-md-flex"></v-col>
    </v-row>
    <!-- dialog -->
    <MsgDialog :dialogProps="dialog">
      <template v-slot:custom-card-actions>
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('home')"
          v-if="dialog.btns.home"
          >回首頁</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('login')"
          v-if="dialog.btns.login"
          >返回登入</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('close')"
          v-if="dialog.btns.close"
          >關閉</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="clickDialogBtn('abort')"
          v-if="dialog.btns.abort"
          >跳出</v-btn
        >
      </template>
    </MsgDialog>
    <!-- dialog for processing end -->
  </v-container>
</template>
<script>
import { apiInstallationGetIndex } from '@api/installation.js';
// import ArrayHandler from '@util/common/arrayHandler.js';
import LoginVerify from '@util/common/loginVerify.js';
import getCS from '@util/common/getCS.js';
import MsgDialog from '@component/dialog/MsgDialog';

// const avatars = [
//   '?accessoriesType=Blank&avatarStyle=Circle&clotheColor=PastelGreen&clotheType=ShirtScoopNeck&eyeType=Wink&eyebrowType=UnibrowNatural&facialHairColor=Black&facialHairType=MoustacheMagnum&hairColor=Platinum&mouthType=Concerned&skinColor=Tanned&topType=Turban',
//   '?accessoriesType=Sunglasses&avatarStyle=Circle&clotheColor=Gray02&clotheType=ShirtScoopNeck&eyeType=EyeRoll&eyebrowType=RaisedExcited&facialHairColor=Red&facialHairType=BeardMagestic&hairColor=Red&hatColor=White&mouthType=Twinkle&skinColor=DarkBrown&topType=LongHairBun',
//   '?accessoriesType=Prescription02&avatarStyle=Circle&clotheColor=Black&clotheType=ShirtVNeck&eyeType=Surprised&eyebrowType=Angry&facialHairColor=Blonde&facialHairType=Blank&hairColor=Blonde&hatColor=PastelOrange&mouthType=Smile&skinColor=Black&topType=LongHairNotTooLong',
//   '?accessoriesType=Round&avatarStyle=Circle&clotheColor=PastelOrange&clotheType=Overall&eyeType=Close&eyebrowType=AngryNatural&facialHairColor=Blonde&facialHairType=Blank&graphicType=Pizza&hairColor=Black&hatColor=PastelBlue&mouthType=Serious&skinColor=Light&topType=LongHairBigHair',
//   '?accessoriesType=Kurt&avatarStyle=Circle&clotheColor=Gray01&clotheType=BlazerShirt&eyeType=Surprised&eyebrowType=Default&facialHairColor=Red&facialHairType=Blank&graphicType=Selena&hairColor=Red&hatColor=Blue02&mouthType=Twinkle&skinColor=Pale&topType=LongHairCurly'
// ];
// const pause = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
  components: { MsgDialog },
  data () {
    return {
      title: '需求單一覽表',
      logger: {}, // 登入者
      csOpts: {}, // 代碼對照檔
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        title: '',
        msg: '',
        process: false,
        persistent: false,
        btns: {
          close: false // 關閉訊息窗
          // login: false, // 返回會員燈入頁
          // home: false, // 返回平台首頁
          // abort: false // 跳出目前視窗
        }
      },
      panels: { // expension panels
        search: 0 // non-multiple
      },
      formVars: {
        seo: {
          label: '關鍵字搜尋',
          value: '',
          rules: [],
          type: 'text'
        }
      },
      instVars: {
        headers: {
          is_seq: {
            id: 'is_seq',
            label: '需求單號'
          },
          buy_type: {
            id: 'buy_type',
            label: '車主身分'
          },
          brand: {
            id: 'brand',
            label: '車廠'
          },
          model: {
            id: 'model',
            label: '車款'
          },
          slip_type: {
            id: 'slip_type',
            label: '編號類別'
          },
          slip_code: {
            id: 'slip_code',
            label: '編號'
          },
          parking_addr: {
            id: 'parking_addr',
            label: '場勘地址'
          },
          parking_env: {
            id: 'parking_env',
            label: '車位所在的住家型態'
          },
          community: {
            id: 'community',
            label: '社區名稱'
          },
          parking_type: {
            id: 'parking_type',
            label: '車位種類'
          },
          parking_type_remark: {
            id: 'parking_type_remark',
            label: ''
          },
          parking_model: {
            id: 'parking_model',
            label: '車位型式'
          },
          parking_loc: {
            id: 'parking_loc',
            label: '地上/地下'
          },
          parking_floor: {
            id: 'parking_floor',
            label: '車位樓層'
          },
          parking_no: {
            id: 'parking_no',
            label: '車位編號'
          },
          contact_name: {
            id: 'contact_name',
            label: '(場勘)聯絡人姓名'
          },
          contact_tel: {
            id: 'contact_tel',
            label: '(場勘)聯絡人電話'
          },
          community_tel: {
            id: 'community_tel',
            label: '(場勘)社區電話'
          },
          community_meeting_dt: {
            id: 'community_meeting_dt',
            label: '(場勘)社區下次管委會日期'
          },
          assign_type: {
            id: 'assign_type',
            label: '是否指定裝機廠商'
          },
          assign_remark: {
            id: 'assign_remark',
            label: ''
          },
          opinions: {
            id: 'opinions',
            label: '特別需求/意見'
          }
        },
        items: []
      },
      // treeVars: {
      //   active: [],
      //   items: []
      // }
      treeVars: {
        active: [],
        avatar: null,
        open: [],
        items: [] // by ref_seq / is_seq
      }
    };
  },
  computed: {
    // treeItems () {
    //   return [
    //     {
    //       name: 'Users',
    //       children: this.treeVars.users
    //     }
    //   ];
    // },
    // treeSelected () {
    //   if (!this.treeVars.active.length) return undefined;

    //   const id = this.treeVars.active[0];

    //   return this.treeVars.users.find(function (user) { user.id === id; });
    // }
  },
  watch: {
    // selected: 'randomAvatar'
  },
  methods: {
    // async fetchUsers (item) {
    //   // Remove in 6 months and say
    //   // you've made optimizations! :)
    //   await pause(1500);

    //   return fetch('https://jsonplaceholder.typicode.com/users')
    //     .then(res => res.json())
    //     .then(json => (item.children.push(...json)))
    //     .catch(err => console.warn(err));
    // },
    // randomAvatar () {
    //   this.treeVars.avatar = avatars[Math.floor(Math.random() * avatars.length)];
    // },
    /**
     * [initSet] 初始化
     */
    initSet () {
      if (!!this.logger && !!this.logger.mem_uuid) {
        // 取得代碼對照表
        let param = { 20: 'brand', 21: 'model', 22: 'slip_type', 30: 'parking_type', 31: 'parking_env', 32: 'parking_model', 33: 'community' };
        getCS.getCsByFilter(param, this.csOpts, '');

        // 取得車主需求單清單
        param = { caller: process.env.VUE_APP_USER, member_uid: this.logger.mem_uuid };
        this.getInstData(param);
      }
    },
    /**
     * [getInstData] 取得 需求單清單
     * @params Object param
     */
    async getInstData (param) {
      const _dialog = this.$options.data().dialog;
      if (param.member_uid == null || param.member_uid === '') {
        _dialog.msg = '查詢需求單 - 入參不正確';
        this.$set(this, 'dialog', _dialog);
        return false;
      }

      const _errCode = {
        102: '查無資料',
        105: '查詢需求單發生異常'
      };

      // call api
      let fail = {};
      let result = null;
      try {
        // axios api
        // console.log(param);
        result = await apiInstallationGetIndex(param);
        // console.log(result);
        // 失敗
        if (!result || !result.status) {
          fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
          // alert(`登入失敗，錯誤訊息: [${fail.status}] ${fail.msg}`);
          _dialog.msg = _errCode[101] + '，結果: ' + result.msg;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
      } catch (error) {
        fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
        // dialog with error
        _dialog.title = _errCode[105];
        _dialog.msg = _errCode[105] + '，error: ' + error;
        console.log(_errCode[105]);
        console.log(fail);
        this.$set(this, 'dialog', _dialog);
        return false;
      }
      // success
      // _dialog.msg = '查詢成功';
      // this.$set(this, 'dialog', _dialog);
      // 解析
      if (result.data.length > 0) {
        const _tree = {};
        let idx = 1;
        for (let i = 0; i < result.data.length; i++) {
          // decode content
          const r = result.data[i];
          this.decodeInstData(r);
          // treeViews (by ref_seq / is_seq)
          if (_tree[r.ref_seq] == null) {
            _tree[r.ref_seq] = {
              id: idx++,
              name: `【群組單號】${r.ref_seq}`,
              children: []
            };
          }
          _tree[r.ref_seq].children.push({ id: idx++, name: `【需求單號】${r.is_seq}`, rid: this.instVars.items.length - 1 });
        }
        this.$set(this.treeVars, 'items', Object.values(_tree));
        // console.log(this.treeVars);
      }
    },
    /**
     * [decodeInstData] 解析需求單資料(單一張)
     */
    decodeInstData (data) {
      // console.log(data);
      const row = data;
      this.instVars.items.push(row);
    }
  },
  created () {
    this.logger = LoginVerify.getLogger('member');
    this.initSet();
    // console.log(this.csOpts);
  },
  mounted () {
    console.log('[installation/list][mounted] ...');
    console.log(this.instVars);
    console.log(this.treeVars);
  },
  updated () {
    console.log('[installation/list][mounted] ...');
    console.log(this.instVars);
    console.log(this.treeVars);
  }
};
</script>
<style scoped>
</style>
